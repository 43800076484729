import React from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
import Seo from "../components/seo"


const IndexPage = ({ data, location }) => {
    var comarques= data.comarques.edges
    var agenda = data.fires.edges
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
    </Helmet>
    <Seo title="Home" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >

    <div class="jumbotronhome jumbotron p-4 p-md-5 text-white rounded bg-light">
          <div class="col-md-10 px-0">
            <h1 class="display-4 font-italic">L'agenda de fires, festes, mercats de Catalunya</h1>
            <p class="lead my-3">L'agenda de fires, festes i mercats de Catalunya està plena d'esdeveniments emocionants i tradicionals durant tot l'any.</p>
            <p class="lead mb-0"><Link to="/agenda/" class="text-white font-weight-bold">Veure l'agenda</Link></p>
          </div>
      </div>
      
      <h2 class="blog-post-title">Agenda de la setmana</h2>
      <main role="main" class="mt-4 container">

        <div class="row mb-4">
        {agenda.map(node => (
          <div class="col-xs-12 col-md-4 mb-4" key={node.artist.id}>
              <div class="card">
                <div className="imgcaja">
                <img class="card-img-top" src={node.artist.img} alt={node.artist.title} />
                </div>
                <div class="card-body">
                  <Link to={`/esdeveniment/${node.artist.slug}/`}>
                  <h3 class="card-title">{node.artist.title}</h3>
                  </Link>
                </div>
              </div>
          </div>
        ))}
        <p align="right"><Link to="/agenda/">Veure tota l'agenda >></Link></p>

        </div>

        <div class="">
          <div class="blog-main">
            <div class="blog-post">
              <h2 class="blog-post-title">Benvinguts a firesifestescatalunya.cat</h2>
              <hr />
              <p>Benvinguts a firesifestescatalunya.cat, la web on trobareu una àmplia selecció de fires, festes i mercats que se celebren a Catalunya al llarg de tot l'any. Amb més de 300 esdeveniments recollits de totes les comarques, aquesta web és el punt de referència per a tots aquells que vulguin viure de prop la cultura i la tradició catalana. Aquí trobareu informació detallada sobre cada esdeveniment, així com fotografies, vídeos i comentaris de la gent que els ha viscut. </p>
              <p>Sigui quin sigui el vostre interès -des de la gastronomia als espectacles populars- estem segurs que hi trobareu l'esdeveniment perfecte per gaudir i conèixer de prop la cultura i la gent de Catalunya. Així que endinseu-vos en aquest món de festes, fires i mercats i descobriu tot el que Catalunya us ofereix.</p>
              <p>T'ajudem a conèixer Catalunya, poble a poble, a través de les seves <i>fires, festes, mercats, espectacles, festivals, mostres, trobades, ...</i></p>
              <p><strong>Busquem, recollim i organitzem</strong> tots els <strong>esdeveniments</strong> festius i culturals de <strong>Catalunya</strong>.</p>
              <p>Prepara el <strong>cap de setmana</strong> amb el cercador per <strong>dormir</strong> i <strong>menjar</strong>. Catalunya és per viure-la. <strong>Aquí comença la festa!</strong></p>
            </div>
      
          </div>
      
        </div>
        <h2 itemProp="headline">Fires i festes per Comarca de Catalunya</h2>
        <section className="row">
        {comarques.map(node => (
          <div class="col-xs-12 col-md-4 mb-4" key={node.artist.id}>
              <div class="card">
                <img class="card-img-top" src={`/imgs/comarques/${node.artist.slug}.jpg`} alt={node.artist.name} />
                <div class="card-body">
                  <Link to={`/comarca/${node.artist.slug}/`}>
                  <h3 class="card-title">{node.artist.name}</h3>
                  </Link>
                </div>
              </div>
          </div>
        ))}
        </section>
      
      </main>
    </article>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    fires: allMysqlFires(
      limit: 6
      filter: {finished: {eq: 0}}
      sort: {order: ASC, fields: ffin}
    ) {
      edges {
        artist: node {
          id
          slug
          img
          title
        }
      }
    }
    comarques: allMysqlComarques (sort: { fields: [name], order: ASC }) {
      edges {
        artist: node {
          id
          name
          slug
        }
      }
    }
  }
`
